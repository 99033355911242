<template>
  <div>
    <div class="box">
      <el-page-header
        @back="$router.go(-1)"
        :content="content"
      ></el-page-header>
    </div>
    <el-card class="content mt20">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('chapter.title')" prop="title">
          <el-input
            v-model="ruleForm.title"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('chapter.status')" prop="status">
           <el-radio-group size="medium" v-model="ruleForm.status">
              <el-radio :label="0">{{status(0)}}</el-radio>
              <el-radio :label="1">{{status(1)}}</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import { createChapter, editChapter, chapterDetail } from "@/api/index";
export default {
  name: "editChapter",
  data() {
    return {
      ruleForm: {
        title: "",
        status:0,
        subject:+this.$route.params.subjectId
      },
      rules: {
        title: [
          {
            required: true,
            message: this.$t("chapter").title,
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("chapter").status,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  methods: {
    status(status) {
      switch (status) {
        case 0:
          return this.$t("chapter.status0");
        case 1:
          return this.$t("chapter.status1");
      }
    },
      async submitForm(formName) {
      await this.$refs[formName].validate();
      if (this.id === undefined) {
        await createChapter(this.ruleForm);
      } else {
        await editChapter({id:this.id,...this.ruleForm});
      }
      this.$router.go(-1);
    },
    async get_info() {
      const information = await chapterDetail(this.id);
      this.ruleForm = { ...information };
    },
  },
  async created() {
    if (this.id === undefined) return;
    this.get_info();
  },
};
</script>